// Contents
// @S1 - Color Theming Variables
// @S2 - Margins & Padding Variables
// @S3 - Font Variables
// @S4 - Styling & Mixins

// @S1 ---- Color Theming Variables ----
// -------- Base Colors --------

// This is where we'll put a mixin to select theme styles based on input
// For now it'll do as a staging ground or the base colors
// Shayler Shepherd - July 19, 2021

@color-base-orange-50: rgba(255, 230, 217, 1); // #FFE6D9
@color-base-orange-100: rgba(255, 212, 191, 1); // #FFD4BF
@color-base-orange-200: rgba(255, 187, 153, 1); // #FFBB99
@color-base-orange-300: rgba(255, 153, 102, 1); // #FF9966
@color-base-orange-400: rgba(255, 128, 64, 1); // #FF8040
// @color-base-orange-400: rgba(255, 128, 64, 1); // #FF8040
@color-base-orange-500: rgba(255, 85, 0, 1); // #FF5500
// @color-base-orange-600: rgba(214, 72, 0, 1); // #D64800
@color-base-orange-700: rgba(193, 65, 0, 1); // #C14100
@color-base-orange-800: rgba(162, 55, 0, 1); // #A23700
@color-base-orange-900: rgba(146, 49, 0, 1); // #923100
@color-base-navy-50: rgba(233, 235, 236, 1); // #E9EBEC
@color-base-navy-100: rgba(200, 205, 209, 1); // #C8CDD1
@color-base-navy-200: rgba(168, 175, 181, 1); // #A8AFB5
@color-base-navy-300: rgba(124, 135, 144, 1); // #7C8790
@color-base-navy-400: rgba(81, 95, 107, 1); // #515F6B
@color-base-navy-500: rgba(37, 55, 70, 1); // #253746
@color-base-navy-500-half: rgba(37, 55, 70, 0.5); // #253746
@color-base-navy-600: rgba(21, 41, 58, 1); // #15293A
@color-base-navy-700: rgba(8, 24, 38, 1); // #081826
@color-base-ash: rgba(208, 211, 212, 1); // #D0D3D4
@color-base-steel: rgba(124, 135, 142, 1); // #7C878E
@color-base-slate: rgba(66, 85, 99, 1); // #425563
// @color-base-cyan-50: rgba(229, 246, 250, 1); // #E5F6FA
@color-base-cyan-100: rgba(211, 240, 247, 1); // #D3F0F7
// @color-base-cyan-200: rgba(184, 231, 243, 1); // #B8E7F3
@color-base-cyan-300: rgba(149, 219, 236, 1); // #95DBEC
@color-base-cyan-400: rgba(113, 207, 230, 1); // #71CFE6
@color-base-cyan-500: rgba(78, 195, 224, 1); // #4EC3E0
// @color-base-cyan-600: rgba(44, 178, 210, 1); // #2CB2D2
@color-base-cyan-700: rgba(8, 155, 190, 1); // #089BBE
@color-base-cyan-800: rgba(0, 125, 154, 1); // #007D9A
@color-base-cyan-900: rgba(6, 108, 132, 1); // #066C84
@color-base-navy-50-half: rgba(248, 249, 249, 1); // #F8F9F9
// @color-base-navy-600-half: rgba(21, 41, 58, 1); // #8A949C
@color-base-success: rgba(0, 110, 24, 1); // #006E18
@color-base-danger: rgba(193, 0, 27, 1); // #C1001B
@color-base-warning-high: rgba(255, 156, 0, 1); // #FF9C00
@color-base-warning-low: rgba(255, 233, 0, 1); // #FFE900
@color-base-white: rgba(255, 255, 255, 1); // #FFFFFF
@color-base-ash-half: rgba(208, 211, 212, 1); // #e7e9e9
// @color-base-ash-half: rgba(208, 211, 212, 0.5); // #e7e9e9
@color-base-navy-500-26: rgba(37, 55, 70, 0.26);
@color-base-unknown-01: #6c000f; // #6C000f

// -------- Color Mapping --------
// Backgrounds
@color-background: @color-base-white;
@color-background-alt: @color-base-ash-half;
@color-background-row-alt: @color-base-navy-50-half;
@color-background-element: @color-base-navy-50-half;
@color-background-element-alt: @color-base-ash;
@color-background-element-dark: @color-base-navy-500;
@color-background-icon-alt: @color-base-steel;
@color-background-icon-hover: @color-base-navy-500-26;
@color-background-hover: @color-base-cyan-100;
@color-background-hover-alt: @color-base-orange-700;
@color-background-hover-dark: @color-base-orange-900;
@color-background-selected: @color-base-orange-100;
@color-background-selected-alt: @color-base-cyan-500;
@color-background-selected-indicator: @color-base-orange-500;
@color-background-error: @color-base-danger;
@color-background-danger: @color-base-danger;
@color-background-success: @color-base-success;
@color-background-info: @color-base-navy-500;
@color-background-info-alt: @color-base-cyan-800;
@color-background-warning-high: @color-base-warning-high;
@color-background-warning-low: @color-base-warning-low;

// Borders
@color-border: @color-base-ash;
@color-border-card: @color-base-slate;
@color-border-alt: @color-base-navy-200;
@color-border-dark: @color-base-navy-700;
@color-border-hover-alt: @color-base-orange-700;
@color-border-focus: @color-base-orange-700;
@color-border-focus-alt: @color-base-cyan-700;

// Text
@color-text: @color-base-navy-500;
@color-text-alt: @color-base-white;
@color-text-hover-alt: @color-base-orange-700;
@color-text-hover-dark: @color-base-orange-900;
@color-text-element: @color-base-ash;
@color-text-element-alt: @color-base-slate;
@color-text-link: @color-base-cyan-800;
@color-text-link-hover: @color-base-cyan-800; // Pending accessibility fix - normally cyan 500, which is DNP
@color-text-link-focus: @color-base-cyan-800; // Pending accessibiltity fix - normally cyan 500, which is DNP
@color-text-error: @color-base-danger;
@color-text-danger: @color-base-danger;
@color-text-warning-high: @color-base-warning-high;

// Exceptions
@color-button-group-border-selected: @color-base-cyan-800;
@color-text-input-border: @color-base-cyan-400;
@color-input-clear-icon: @color-base-navy-500-half;
@color-notification-background: @color-base-orange-700;
@color-tabs-selected-text: @color-base-orange-700;
@color-button-any-primary: @color-base-cyan-800;
@color-button-any-primary-focus: @color-base-cyan-900;
@color-button-any-secondary: @color-base-slate;
@color-button-any-secondary-focus: @color-base-navy-700;
@color-button-any-danger-focus: @color-base-unknown-01;
@color-switch-background: @color-base-navy-100;
@color-switch-background-hover: @color-base-steel;
@color-switch-background-indicator: @color-base-slate;
@color-range-slider-input-background: @color-base-navy-50;
@color-range-slider-background-hover: @color-base-orange-900;
@color-background-grid-row-hover: @color-base-orange-50;
@color-accordion-text: @color-base-navy-700;
@color-application-menu-icon-hover: @color-base-orange-400;

@color-indicator-weak: @color-base-danger;
@color-indicator-okay: @color-base-warning-high;
@color-indicator-good: @color-base-warning-low;
@color-indicator-strong: @color-base-success;

// @S2 ---- Margins & Padding Variables ----
@spacing-11x: 2.75rem; // 44px
@spacing-10x: 2.5rem; // 40px
@spacing-9x: 2.25rem; // 36px
@spacing-8x: 2rem; // 32px
@spacing-7x: 1.75rem; // 28px
@spacing-6x: 1.5rem; // 24px
@spacing-5x: 1.25rem; // 20px
@spacing-4x: 1rem; // 16px
@spacing-3x: 0.75rem; // 12px
@spacing-2x: 0.5rem; // 8px
@spacing-1x: 0.25rem; // 4px

// @S3 ---- Font Variables ----
@font-branding: 2.125rem; // 34px
@font-size-7x: 1.375rem; //22px
@font-size-6x: 1.25rem; //20px
@font-size-5x: 1.125rem; //18px
@font-size-4x: 1rem; //16px
@font-size-3x: 0.875rem; //14px
@font-size-2x: 0.75rem; //12px
@font-size-1x: 0.625rem; //10px

// Font family
@fonts-default: 'OpenSans-Regular', 'Noto Sans JP', 'Noto Sans KR',
	'Noto Sans SC', 'Noto Naskh Arabic', sans-serif;
@font-family: 'OpenSans-Regular', 'Noto Sans JP', 'Noto Sans KR', 'Noto Sans SC',
	'Noto Naskh Arabic', sans-serif;

// @S4 ---- Styling & Mixins ----

// Buttons - https://beyondtrustshield.zeroheight.com/styleguide/s/14936/p/19e714-buttons/b/32e1a2
// Filled Buttons
.button-fill-base() {
	color: @color-text-alt;
	outline: none;

	&:hover {
		background-color: @color-background-hover-alt;
	}

	&:focus {
		.uxp-outline();
	}
}

.uxp-button-fill-primary() {
	.button-fill-base();
	background-color: @color-button-any-primary;

	&:focus {
		background-color: @color-button-any-primary-focus;
	}
	&:active {
		background-color: @color-button-any-primary-focus;
	}
}

.uxp-button-fill-secondary() {
	.button-fill-base();
	background-color: @color-button-any-secondary;

	&:focus {
		background-color: @color-button-any-secondary-focus;
	}
	&:active {
		background-color: @color-button-any-secondary-focus;
	}
}

.uxp-button-fill-danger() {
	.button-fill-base();
	background-color: @color-background-danger;

	&:focus {
		background-color: @color-button-any-danger-focus;
	}
	&:active {
		background-color: @color-button-any-danger-focus;
	}
}

// Ghost Buttons
.button-ghost-base() {
	border-style: solid;
	background-color: @color-background;

	&:hover {
		color: @color-text-hover-alt;
		border-color: @color-border-hover-alt;
		background-color: @color-background;
	}

	&:focus {
		.uxp-outline();
	}
}

.uxp-button-ghost-primary() {
	.button-ghost-base();
	color: @color-button-any-primary;
	border-color: @color-button-any-primary;

	&:focus {
		color: @color-button-any-primary-focus;
		border-color: @color-button-any-primary-focus;
		background-color: rgba(6, 108, 132, 0.26); // Cyan 900 at 26%
	}
	&:active {
		color: @color-button-any-primary-focus;
		border-color: @color-button-any-primary-focus;
	}
}

// Text Buttons
.button-text-base() {
	outline: none;
	background-color: unset;

	&:hover {
		color: @color-text-hover-alt;
	}

	&:focus {
		.uxp-outline();
	}
}

.uxp-button-text-primary() {
	.button-text-base();
	color: @color-button-any-primary;

	&:focus {
		color: @color-button-any-primary-focus;
	}

	&:active {
		color: @color-button-any-primary-focus;
	}
}

.uxp-button-text-secondary() {
	.button-text-base();
	color: @color-text-element-alt;

	&:focus {
		color: @color-button-any-secondary-focus;
	}

	&:active {
		color: @color-button-any-secondary-focus;
	}
}

// Text with Icon Button
.button-text-icon-base() {
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: unset;
	text-transform: none;

	&:hover {
		color: @color-text-hover-alt;
	}

	&:focus {
		.uxp-outline();
	}

	::ng-deep svg {
		width: 22px;
		height: 22px;
		fill: currentColor;
	}
}

.uxp-button-text-icon-left() {
	.button-text-icon-base();
	color: @color-button-any-primary;
	padding: @spacing-3x @spacing-4x @spacing-3x @spacing-3x;

	&:focus {
		color: @color-button-any-primary-focus;
	}

	&:active {
		color: @color-button-any-primary-focus;
	}

	::ng-deep svg:first-of-type {
		padding-right: @spacing-3x;

		&:focus {
			outline: none;
		}
	}
}

.uxp-button-text-icon-right() {
	.button-text-icon-base();
	color: @color-button-any-primary;
	padding: @spacing-3x @spacing-3x @spacing-3x @spacing-4x;

	&:focus {
		color: @color-button-any-primary-focus;
	}

	&:active {
		color: @color-button-any-primary-focus;
	}

	::ng-deep svg:last-of-type {
		padding-left: @spacing-3x;
	}
}

// Icons - https://zeroheight.com/5fc6f3b32/p/724a97-icons/b/09dffe
.uxp-icon {
	width: 46px;
	height: 46px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid rgba(0, 0, 0, 0);
	border-radius: 50%;

	&:hover {
		background-color: @color-background-icon-hover;
		//border-color: @color-background-icon-hover;
	}
	// width: 24px;
	// height: 24px;
	// fill: currentColor;
	// padding: @spacing-2x;
	// overflow: visible;
	// cursor: pointer;

	// &:active {
	// 	background-color: rgba(104, 119, 130, 0.15);
	// 	padding: @spacing-2x;
	// 	border: 1px dashed @color-border-focus;
	// 	border-radius: 20px;
	// }

	// &.action-icon {
	// 	color: @color-text-link;
	// }

	// &.warning-icon {
	// 	color: @color-background-danger;
	// }

	// &.static-icon {
	// 	color: @color-text;
	// }
}

.uxp-input {
	font-size: @font-size-4x;
	line-height: 18px;
	padding: @spacing-2x @spacing-6x @spacing-2x @spacing-2x;
	border: 1px solid @color-border-alt;
	color: @color-text;
	width: 361px;
	cursor: text;

	&:focus {
		border-color: @color-border-focus-alt;
		.uxp-outline();
	}

	&.error {
		border-color: @color-background-error;
		&:focus {
			border-color: @color-background-error;
		}
	}

	&[readonly] {
		cursor: none;
		pointer-events: none;
		border: 1px solid @color-border;
		background-color: rgba(208, 211, 212, 0.24);
	}
}

.uxp-radio-checkbox-base() {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	font-size: @font-size-3x;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	height: 36px;

	&:hover {
		.component-input + label {
			color: @color-text-hover-dark;
		}
		.component-input + label::before {
			border-color: @color-text-hover-dark;
		}

		.component-input:checked + label::before {
			border-color: @color-text-hover-dark;
			background-color: @color-text-hover-dark;
		}
	}

	.component-input {
		position: absolute;
		top: auto;
		overflow: hidden;
		clip: rect(1px, 1px, 1px, 1px);
		width: 1px;
		height: 1px;
		white-space: nowrap;
	}

	.component-input + label {
		display: flex;
		align-items: center;
		font-size: @font-size-3x;
		cursor: pointer;
	}

	.component-input:focus + label {
		.uxp-outline();
	}

	.component-input + label::before {
		content: '';
		background: @color-background;
		margin-right: 6px; // Abnormal spacing
		height: 18px;
		width: 18px;
		border: 2px solid @color-background-element-dark;
		background-color: @color-background;
		box-sizing: border-box;
		cursor: pointer;
		transition: all 0.1s ease-in-out;
	}

	&.has-description {
		margin-bottom: @spacing-2x;
		align-items: unset;
	}

	.component-description {
		display: flex;
		flex: 1 1 100%;
		font-size: @font-size-1x;
		line-height: @spacing-4x;
		margin: 0;
		margin-left: @spacing-6x;
	}
}

.uxp-radio-button {
	.uxp-radio-checkbox-base();

	input[type='radio'] {
		& + label::before {
			border-radius: 100%;
		}

		&:checked + label::before {
			background-color: @color-button-any-primary;
			border-color: @color-button-any-primary;
			box-shadow: inset 0 0 0 0.15rem @color-background;
		}
	}
}

.uxp-checkbox {
	.uxp-radio-checkbox-base();
	input[type='checkbox'] {
		& + label::before {
			border-radius: 3px;
		}

		& + label::after {
			display: block;
			content: '';
			position: absolute;
			top: 10px;
			left: 6px;
			width: @spacing-4x;
			height: @spacing-4x;
			transform: rotate(0deg);
			transition: all 0.1s ease-in-out;
		}

		&:checked {
			+ label::before {
				background-color: @color-button-any-primary;
				border-color: @color-button-any-primary;
			}
			+ label::after {
				width: 4px;
				height: 10px;
				transform: rotate(45deg);
				border: 0.125rem solid @color-background;
				border-top: none;
				border-left: none;
			}
		}
	}

	&.has-description input[type='checkbox']:checked + label::after {
		top: 3px;
	}
}

.uxp-switch {
	width: 40px;
	border-radius: 14px;
	height: 24px;
	background-color: @color-switch-background;
	cursor: pointer;
	position: relative;
	transition: background-color 0.25s;
	margin-right: @spacing-2x;

	.switch-indicator {
		left: 0px;
		top: 1px;
		width: 22px;
		height: 22px;
		background-color: @color-switch-background-indicator;
		border-radius: 12px;
		cursor: pointer;
		position: relative;
		transition: left 0.25s, background-color 0.25s;

		&.selected {
			background-color: @color-background-success;
			left: 18px;
		}
	}

	.switch-icon-container {
		left: -1px;
		top: -1px;
		position: relative;

		.switch-icon {
			width: 24px;
			height: 24px;
			color: @color-text-alt;
		}
	}
}

.uxp-card {
	display: flex;
	flex-direction: column;
	// border: 1px solid @color-border;
	border: 1px solid @color-border-card;
	// background-color: @color-background;
	background-color: @color-base-navy-600;
	color: @color-base-ash-half;

	& > * {
		padding-left: @spacing-4x;
		padding-right: @spacing-4x;
		padding-bottom: @spacing-2x;

		&:first-child {
			padding-top: @spacing-5x;
		}
	}
}

.uxp-label-value-pair {
	display: flex;
	flex-direction: column;
	align-items: baseline;
	padding-top: @spacing-2x;
	padding-bottom: @spacing-2x;

	.label,
	.value {
		font-size: @font-size-2x;
		color: @color-text;
		max-width: 108px;
		line-height: 20px;
		word-wrap: break-word;
		font-family: @fonts-default;
		max-width: 620px;
	}

	.value {
		font-size: @font-size-3x;
		margin: 0;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&.prominent-label .label {
		font-family: 'OpenSans-Semibold';
	}

	&.prominent-value .value {
		font-family: 'OpenSans-Semibold';
	}

	.value-icon {
		width: 16px;
		height: 16px;
		padding-right: @spacing-2x;
		align-self: center;
	}

	&:last-child {
		padding-bottom: @spacing-2x;
	}
}

.uxp-banner {
	.banner-container {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: space-between;
		background-color: @color-background-alt;
		font-size: @font-size-3x;
		margin: @spacing-2x 0;

		&.banner-error {
			border-top: 4px solid @color-background-error;
		}

		&.banner-warning {
			border-top: 4px solid @color-background-warning-high;
		}

		&.banner-info {
			border-top: 4px solid @color-background-info-alt;
		}
	}

	.banner-title {
		padding: 13px @spacing-4x 13px 13px; // Abnormal spacing
		display: flex;
		flex-direction: row;
		align-items: center;
		max-width: 700px;
	}

	.banner-icon {
		height: 22px;
		width: 22px;
		margin-right: @spacing-2x;
	}

	.banner-icon-error {
		color: @color-text-error;
	}

	.banner-icon-warning {
		color: @color-background-warning-high;
	}

	.banner-icon-info {
		color: @color-background-info-alt;
	}

	.banner-actions-list {
		display: flex;
		flex-direction: row;
		padding: 6px 0; // Abnormal spacing
		align-items: flex-end;
		margin-right: 6px; // Abnormal spacing

		app-uxp-button ::ng-deep .btn-container {
			margin: 0;
			padding: 0;
		}
		app-uxp-button:last-child {
			::ng-deep .btn-container {
				margin: 0;
			}
		}
	}

	.banner-content-container {
		::ng-deep app-uxp-accordion {
			margin-left: 33px; //Abnormal spacing
			.accordion-button-container {
				.accordion-btn {
					color: @color-text;
				}
				.accordion-label {
					font-size: @font-size-3x;
					color: @color-text;
				}
			}
		}
	}
}

.uxp-number-field {
	.number-field-container {
		display: flex;
		flex-direction: column;
		padding-top: @spacing-2x;
		padding-bottom: @spacing-2x;
		margin-right: @spacing-4x;
	}

	.number-field-label {
		font-size: @font-size-2x;
	}

	.number-field-control-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: @spacing-2x;
	}

	.number-field-icon-container {
		cursor: pointer;
		display: flex;
		padding: @spacing-3x 0;
		transition: color 0.2s ease-in-out;

		&:hover {
			color: @color-background-hover-dark;
		}

		.number-field-icon {
			height: 22px;
			width: 22px;
		}
	}

	.number-field-input {
		width: 70px;
		padding: @spacing-2x;
		text-align: center;
		margin: 0 @spacing-2x;
	}

	.number-field-instructions {
		font-size: @font-size-1x;
	}

	.number-field-error {
		font-size: @font-size-1x;
		color: @color-text-error;
		max-width: 145px;
	}

	.sentence {
		flex-direction: row;

		.number-field-control-container {
			margin-top: 0px;
			padding-left: @spacing-3x;
			padding-right: @spacing-3x;
			flex-wrap: wrap;
			max-width: 148px;
		}

		.number-field-label,
		.number-field-instructions {
			font-size: @font-size-2x;
			margin-top: @spacing-3x;
			line-height: 22px;
		}

		&.error {
			.number-field-label,
			.number-field-instructions {
				color: @color-text-error;
			}
		}
	}
}

.uxp-dialog {
	.dialog-wrapper {
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 20%);
		position: fixed;
		top: 0;
		left: 0;
		z-index: 500;
		display: flex;
		font-family: @fonts-default;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.dialog-container {
		box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 15%);
		max-width: 520px;
		background-color: @color-background;
	}

	.dialog-title {
		padding: @spacing-5x @spacing-4x 0 @spacing-4x;
		text-transform: uppercase;
		font-family: 'OpenSans-Semibold';
		font-size: @font-size-6x;
	}

	.dialog-content {
		padding: @spacing-4x;
		font-size: @font-size-3x;
	}

	.dialog-actions-list {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		padding: 0px @spacing-4x @spacing-4x @spacing-4x;

		app-uxp-button ::ng-deep .btn-container {
			margin-top: 0;
			margin-bottom: 0;
		}
		app-uxp-button:last-child {
			::ng-deep .btn-container {
				margin-right: 0;
			}
		}
	}
}

// Used for any list items (combobox, dropdown, list box, etc)
.list-item-hover {
	&:hover {
		background-color: @color-background-hover;
	}
}
.list-item-selected {
	&.item-selected {
		background-color: @color-background-selected;
	}
}

.list-item-base {
	.list-item-hover();
	.list-item-selected();
	display: flex;
	flex-direction: row;
	min-height: 58px;
	justify-content: space-between;
	align-items: center;
	width: -webkit-fill-available;
	padding: 0px @spacing-4x;
	cursor: pointer;
}

.uxp-list-item-primary {
	.list-item-base();
}

.uxp-list-item-secondary {
	.list-item-base();

	span {
		font-size: @font-size-2x;
	}
}

.uxp-text-link {
	display: flex;
	align-items: center;
	color: @color-text-alt;
	font-size: @font-size-6x;
	line-height: @spacing-6x;
	letter-spacing: 0;
	margin-bottom: @spacing-2x;
	margin-top: @spacing-2x;

	&:hover {
		color: @color-text-hover-alt;
		cursor: pointer;
	}

	&:focus {
		.uxp-outline();
	}

	.link-icon {
		fill: currentColor;
		height: 16px;
		width: 16px;
		padding: 0;
		margin-right: @spacing-1x;
	}
}

.uxp-accordion {
	width: 200px;
	height: 0%;
	overflow: hidden;
	display: none;
	background-color: blue;

	//animation: animate-height-hide 0.25s;

	&.open {
		animation: animate-height-show 0.25s;

		display: flex;
		height: 200px;
	}

	&.closed {
		animation: animate-height-show 0.25s;
		display: flex;
		height: 0%;
	}
}

.uxp-outline {
	outline: 1px dashed @color-border-focus;
	outline-offset: 2px;
}

.uxp-icon-outline {
	border: 1px dashed @color-border-focus;
}

.uxp-form-container {
	& + .form-container {
		app-uxp-button {
			margin-top: @spacing-4x;
		}
	}
}

.uxp-tooltip {
	.tooltip {
		position: relative;
		display: inline-block;

		&:hover {
			border-radius: 50%;
			.tooltip-text {
				visibility: visible;
			}
		}

		.tooltip-text {
			--tooltip-width: max-content;
			--tooltip-margin: 37px;
			visibility: hidden;
			background-color: @color-background-element-alt;
			color: @color-text;
			font-size: @font-size-3x;
			line-height: @font-size-6x;
			text-align: center;
			border-radius: 2px;
			bottom: 120%;
			left: 50%;
			padding: 0 @spacing-2x;
			width: var(--tooltip-width);
			margin-left: -38px;

			position: absolute;
			z-index: 1;
		}
	}
}

.no-select {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

@keyframes animate-height-show {
	0% {
		height: 0%;
	}
	1% {
		height: 0%;
	}
	100% {
		height: 100%;
	}
}

@keyframes animate-height-hide {
	0% {
		height: 100%;
	}
	99% {
		height: 0%;
	}
	100% {
		height: 0%;
	}
}
